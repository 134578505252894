.offer-content {

  & .svg-img-icon {
    width: 60px
  }

  & .rebooking-img-edit {
    height: 280px;
  }

  & .rebooking-img-edit img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 768px) {
    & .rebooking-img-edit {
      height: 200px;
    }
  }

  & .rebookingItem a span.itemName {
    background-color: #F20C19;
    border: 1px solid #F20C19;
    color: #fff;
  }
}
