// fixed-width icons
// -------------------------

.#{$fa-css-prefix}-fw {
  text-align: center;
  width: $fa-fw-width;
  height: $fa-fw-width;
  display: flex;
  justify-content: center;
  align-items: center;
}
