.swap {
  position: relative;
  display: inline-grid;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  place-content: center;
  cursor: pointer;
  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform, opacity;
  }
}

.swap-rotate > *:first-child, .swap-rotate .swap-indeterminate, .swap-rotate input:indeterminate ~ *:first-child {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.swap-rotate[active] ~  *:last-child, .swap-rotate[active] > *:last-child, .swap-rotate input:indeterminate ~ *:last-child {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.swap[active] *:first-child, .swap[active] .swap-indeterminate, .swap[active] input:indeterminate ~ *:first-child {
  opacity: 0;
}

.swap:not([active]) ~ *:last-child, .swap:not([active]) > *:last-child, .swap:not([active]) input:indeterminate ~ *:last-child {
  opacity: 0;
}


