
*[role="button"] {
  cursor: pointer;
}

.hoverScale {
  transform: scale(1);
  -webkit-transition: all 300ms linear !important;
  -moz-transition: all 300ms linear !important;
  -ms-transition: all 300ms linear !important;
  -o-transition: all 300ms linear !important;
  transition: all 300ms linear !important;
}

.hoverScale:hover {
  transform: scale(1.01);
  -webkit-transition: all 300ms linear !important;
  -moz-transition: all 300ms linear !important;
  -ms-transition: all 300ms linear !important;
  -o-transition: all 300ms linear !important;
  transition: all 300ms linear !important;
}

.MuiPopover-paper.MuiPaper-root {
  border-radius: 5px;
}

.html{
  & p{
    margin: 0 0 5px;
  }
}
