@import "./fontawesome/variables.scss";
@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/solid.scss";
@import "./fontawesome/light.scss";
@import "./fontawesome/regular.scss";
@import "./fontawesome/thin.scss";
@import "./fontawesome/brands.scss";
@import "./fontawesome/duotone.scss";
@import "./fontawesome/_duotone-icons.scss";
@import "./custom";
@import "./offers";
@import "./flipStyle";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swal2-container {
  z-index: 99999999999999999999999999999999;
}
/*#ventrata-checkout-loader {
  z-index: 9999999999999999999999999999999 !important;
}*/
.loginIframe {
  border-width: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fffffe+0,f7f7f7+100 */
  background: #fffffe; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #fffffe 0%, #f7f7f7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #fffffe 0%, #f7f7f7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #fffffe 0%, #f7f7f7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffffe', endColorstr='#f7f7f7', GradientType=1); /* IE6-9 fallback on horizontal gradient */

}

#root main {
  overflow-y: scroll;
  min-height: 100vh;
}

a {
  text-decoration: unset;
  color: initial;
}

a:hover {
  color: inherit;
}

img {
  width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6, p, span, a {
  margin: 0;
  padding: 0;
}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.unselectable {
  pointer-events: none;
}

.MuiDrawer-paper {
  overflow: hidden;
}

// Credit Card
.rccs {
  margin: 0;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
